import React, {useState} from 'react'
import classcat from 'classcat'
import QRCode from 'react-qr-code'
import {useSelector, useDispatch} from 'react-redux'

import Button from 'src/components/Button'
import ModalTemplate from 'src/components/ModalTemplate'

import {setWalletState} from 'src/actions/data'
import {setDefaultModal, setLoading} from 'src/actions/element'
import sendRequest from 'src/helpers/service'
import {handleError} from 'src/helpers/errorHandler'

import c from './giftDetail.module.css'

import giftDetailTop from 'src/assets/images/gift-detail/top.png'
import giftDetailHiddenCode from 'src/assets/images/gift-detail/hidden-code.png'
import giftDetailShare from 'src/assets/images/gift-detail/share.svg'
import giftDetailCopy from 'src/assets/images/gift-detail/copy.png'
import giftDetailArrow from 'src/assets/images/gift-detail/arrow.png'
import giftDetailDownArrow from 'src/assets/images/gift-detail/down-arrow.png'
import confetti from 'src/assets/images/confetti.png'

export default function GiftDetail({onClose, gift, customNavigate,loadGifts = null}) {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)
  const [dropdownStatus, setDropdownStatus] = useState(false)
  const [copyStatus, setCopyStatus] = useState(false)

  const useLaterButtonHandler = () => {
    window.logEvent({
      event: 'useCodeLater',
      Category: 'Button',
      Action: 'click',
      Label: 'Sonra Kullan',
      UserID: user.CustomerID,
      totalCoins: user.Points,
      productName: gift.Title,
    })

    customNavigate('/biriktirdiklerim')
    onClose()
  }

  const installLaterButtonHandle = () => {
    window.logEvent({
      event: 'installLater',
      Category: 'Button',
      Action: 'click',
      Label: 'Daha Sonra Yükle',
      UserID: user.CustomerID,
      totalCoins: user.Points,
      productName: gift.Title,
    })

    customNavigate('/biriktirdiklerim')
    onClose()
  }

  const handleCopy = (share) => {
    try {
      navigator.clipboard.writeText(gift.GiftCode)
      navigator.share({
        text: gift.GiftCode,
      })
    } catch (e) {
      console.log(e)
    }

    window.logEvent({
      event: 'storage_buttons',
      UserID: user.CustomerID,
      button_name: 'Kopyala',
    })

    setCopyStatus(true)
    setTimeout(() => {
      setCopyStatus(false)
    }, 4000)
  }

  const handleDataTransfer = () => {
    if (!gift.isDataAvailable) return
    window.logEvent({
      event: 'transfertoNumber',
      Category: 'Button',
      Action: 'click',
      Label: 'Kayıtlı Numarama Aktar',
      UserID: user.CustomerID,
      totalCoins: user.Points,
      productName: gift.Title,
    })

    dispatch(setLoading(true))

    sendRequest
      .post('electra/api/promo/useInternetGift', {
        CustomerID: user.CustomerID,
        GiftCode: gift.GiftCode,
      })
      .then((data) => {
        dispatch(
          setDefaultModal({
            icon: confetti,
            iconSize: 'large',
            title: 'TEBRİKLER!',
            message: data[0].Message,
            button: 'TAMAM',
          })
        )
        onClose()
        dispatch(setWalletState(false))

        // logEvent("completeRedemption", false, {
        //   offer_name: header
        // });
      })
      .catch((err) => {
        handleError(err, dispatch)
        // errorCode: 11504
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const handleArchive = async () => {
    if (!gift.IsArchive) return

    
    dispatch(setLoading(true))
    sendRequest
      .post('electra/api/promo/SaveCustomerArchive', {
        StickCodeID: gift.StickCodeID,
        CustomerID: user.CustomerID,
        GiftCode: gift?.GiftCode,
      })
      .then((data) => {
        window.logEvent({
          event: 'arsiv_success',
          UserID: user.CustomerID || '',
          product_name: gift.Title || ''
        })
        onClose()
        loadGifts()
      })
      .catch((err) => {
        handleError(err, dispatch)
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const howToButtonHandle = () => {
    customNavigate({
      pathname: '/kodu-nasil-kullanirim',
      state: {
        title: 'KODU NASIL KULLANIRIM',
        html: gift.Policy,
      },
    })
    window.logEvent({
      event: 'getprize_howtouse',
      UserID: user.CustomerID,
      prize_name: gift.Title,
    })
    onClose()
  }

  const share = gift.ForGift

  return (
    <ModalTemplate
      className={c.modal}
      contentClass={c.content}
      closeClass={c.close}
      onClose={onClose}
      isDrawer
    >
      <img src={giftDetailTop} className={c.coverImage} alt='giftDetailTop' />
      <img className={c.productImage} src={gift.ImageUrl} alt='product' />

      <b className={c.name}>{gift.Title}</b>

      <b className={c.quantity}>
        {gift.GiftTypeID === 0 ? gift.Price : gift.Quantity}
      </b>

      {!share && (gift.GiftTypeID === 1 || gift.GiftTypeID === 0) && (
        <div className={c.qrField}>
          <QRCode
            size={window.isMobile() ? window.innerWidth * 0.4 : 140}
            value={gift.GiftCode}
          />
          <span className={c.qrCode}>{gift.GiftCode}</span>
          {gift.RedeemDescription && (
            <span className={c.redeemDescription}>
              {gift.RedeemDescription}
            </span>
          )}
          <Button
            className={c.button}
            style={{marginTop: 30}}
            text='Sonra Kullan'
            onClick={useLaterButtonHandler}
          />
          <Button
            className={c.button}
            textButton
            text='Yakındaki Marketler'
            onClick={() => {
              customNavigate('/yakindaki-marketler')
              onClose()
            }}
          />
        </div>
      )}

      {((gift.GiftTypeID !== 4 &&
        gift.GiftTypeID !== 1 &&
        gift.GiftTypeID !== 0) ||
        share) && (
        <>
          {share ? (
            <div className={c.shareField}>
              <b className={c.shareText}>Arkadaşlarınla Paylaş:</b>
              <div className={c.shareButtonField}>
                <img
                  src={giftDetailHiddenCode}
                  alt='giftDetailHiddenCode'
                  className={c.hiddenCodeImage}
                  onClick={() => handleCopy(true)}
                />
                <Button
                  secondary
                  className={c.shareButton}
                  onClick={() => handleCopy(true)}
                >
                  <img src={giftDetailShare} alt='giftDetailShare' />
                  <span>ARKADAŞLARINLA PAYLAŞ</span>
                </Button>
              </div>
            </div>
          ) : (
            <div className={c.copyField} onClick={() => handleCopy(false)}>
              <div className={c.copyLeft}>{gift.GiftCode}</div>
              <button
                className={classcat([c.copyButton, copyStatus && c.copied])}
              >
                <img src={giftDetailCopy} alt='giftDetailCopy' />
              </button>
            </div>
          )}
          {copyStatus && (
            <span className={c.copySuccess}>Panoya Kopyalandı</span>
          )}
          {!share && (
            <Button
              className={c.howToButton}
              dark
              onClick={howToButtonHandle}
            >
              KODU NASIL KULLANACAĞIM?
              <img src={giftDetailArrow} alt='giftDetailArrow' />
            </Button>
          )}

          <div className={c.howToDesktop}>
            <div
              className={c.top}
              onClick={() => {
                if (!dropdownStatus)
                  window.logEvent({
                    event: 'storage_buttons',
                    UserID: user.CustomerID,
                    button_name: 'Kodu Nasıl Kullanacağım',
                  })
                setDropdownStatus(!dropdownStatus)
              }}
            >
              Kodu Nasıl Kullanacağım?
              <img src={giftDetailDownArrow} alt='giftDetailDownArrow' />
            </div>
            {dropdownStatus && (
              <div className={c.dropdownContent}>{gift.Policy}</div>
            )}

            {/* {gift?.IsArchive && <button>ARŞİVLE</button>} */}
            {gift?.IsArchive && (
              <Button
                className={c.button}
                style={{marginTop: 30}}
                text='ARŞİVLE'
                onClick={handleArchive}
              />
            )}
          </div>

          <div className={c.bottom}>
            {gift.Expiredate && <b>{gift.Expiredate}</b>}
            <div className={c.bottomText}>{gift.Description}</div>
          </div>
        </>
      )}
      {gift.GiftTypeID === 4 && (
        <>
          <div className={c.phone}>+90 {parsePhone(user.Msisdn)}</div>
          <Button
            className={c.button}
            disabled={!gift.isDataAvailable}
            text='KAYITLI NUMARAMA AKTAR'
            onClick={handleDataTransfer}
          />
          <Button
            style={{marginTop: 5}}
            className={c.button}
            text='DAHA SONRA YÜKLE'
            textButton
            onClick={installLaterButtonHandle}
          />
        </>
      )}
    </ModalTemplate>
  )
}

function parsePhone(phone = '') {
  return (
    '(' +
    phone.substr(0, 3) +
    ') ' +
    phone.substr(3, 3) +
    ' ' +
    phone.substr(6, 2) +
    ' ' +
    phone.substr(8, 10)
  )
}
