import React from 'react'
import ModalTemplate from 'src/components/ModalTemplate'
import c from './agreement.module.css'
import Checkbox from 'src/components/Checkbox'
import Button from 'src/components/Button'
import sendRequest from 'src/helpers/service'
import {useDispatch} from 'react-redux'

export default function AgreementModal({onClose, data}) {
  const [checkValue, setCheckValue] = React.useState(false)
  const [visible, setVisible] = React.useState(true)
  const [agreement, setAgreement] = React.useState(null)
  const dispatch = useDispatch()

  function getHTML(htmlData) {
    return {__html: htmlData}
  }

  const handleClose = (value) => {
    if (value) onClose()
  }

  const handleChangeCheckbox = (e) => {
    setCheckValue((prev) => !prev)
  }

  const fetchContent = async () => {
    try {
      let webScreenContent = await sendRequest.post(
        'electra/api/promo/GetAppConfigurations',
        {DefaultConfigs: false, key: data?.linkContent}
      )
      return webScreenContent?.Data
    } catch (err) {
      handleError(err, dispatch)
      console.log(err, 'fetchContent err')
    }
  }

  const handleLink = async () => {
    const content = await fetchContent()
    if (content) {
      setAgreement(content)
      setVisible(false)
    }
  }

  const handlePress = async () => {
    try {
      let gdprStatus = await sendRequest.post('electra/api/promo/updateGdpr', {
        GDPRPermission: 1,
        CustomerID: data?.customerID,
      })

      if (gdprStatus.ResponseCode === 0) handleClose(true)
    } catch (err) {
      console.log(err, 'updateGdpr err')
    }
  }

  return (
    <ModalTemplate
      className={c.modal}
      contentClass={visible ? c.content : c.contentLegal}
      closeClass={c.close}
      onClose={handleClose}
    >
      {visible ? (
        <div id='intro-modal-content' className={c.container} data-close={true}>
          <div className={c.headerCard}>
            {data?.title && <div>{data.title}</div>}
          </div>

          <div
            id='legal-text'
            className={c.message}
            dangerouslySetInnerHTML={getHTML(data.message)}
          ></div>

          <div className={c.checkboxCard}>
            <Checkbox
              className={c.checkbox}
              value={checkValue}
              onChange={handleChangeCheckbox}
            />

            <div
              className={c.legalCard}
              onClick={handleLink}
              dangerouslySetInnerHTML={getHTML(data?.link)}
            ></div>
          </div>

          <div className={c.buttonCard}>
            <Button
              disabled={!checkValue}
              className={c.submitButton}
              onClick={handlePress}
            >
              {data.ButtonText ? data.ButtonText : 'ONAYLA'}
            </Button>
          </div>
        </div>
      ) : (
        <div style={{height: '100%', width: '80%'}}>
          <div className={c.backButton} onClick={() => setVisible(true)} />
          <div
            className={c.legalContent}
            dangerouslySetInnerHTML={getHTML(agreement)}
          ></div>
        </div>
      )}
    </ModalTemplate>
  )
}
